import "@assets/fonts/icon_font/icon_font.scss";
import "react-toastify/dist/ReactToastify.css";
import "react-tooltip/dist/react-tooltip.css";
import "./assets/styles/app.scss";
import {Routes, Route} from "react-router-dom";

import {RequireAuth, useAuth} from "@hooks/useAuth";

import Layout from "./components/Layout";

import StartPage from "./pages/StartPage";
import LoginPage from "./pages/LoginPage";
import MessengerPage from "./pages/MessengerPage";
import RatingPage from "./pages/RatingPage";
import NotfoundPage from "./pages/NotfoundPage";
import CompanyFromAdminPage from "./pages/CompanyFromAdminPage";
import StudentFromAdminPage from "./pages/StudentFromAdminPage";
import CatalogFromAdminPage from "@pages/AdminCatalog/CatalogFromAdminPage";
import ProfilePage from "./pages/ProfilePage";
import CompanyListPage from "./pages/CompanyListPage";

import StudentFromRatingPage from "./pages/StudentFromRatingPage";
import CompanyFromListPage from "./pages/CompanyFromListPage";
import ShopPage from "./pages/ShopPage";
import {useDispatch, useSelector} from "react-redux";
import {useEffect, useMemo, useState} from "react";
import CookieAccept from "./components/CookieAccept";
import ShopItem from "./pages/ShopItem";
import MessengerFromAdminPage from "./pages/MessengerFromAdminPage";
import ShopFromAdminPage from "./pages/ShopFromAdminPage";
import CareersPage from "@pages/CareersPage";
import EditProfilePage from "@pages/EditProfilePage";
import CreateCareerPage from "@pages/CreateCareerPage";
import ViewCareerPage from "@pages/ViewCareerPage";
import AdminCareersPage from "@pages/AdminCareersPage";
import AdminCreateCareerPage from "@pages/AdminCreateCareerPage";
import {useProfileStudent} from "@hooks/useProfileStudent";
import {useProfileCompany} from "@hooks/useProfileCompany";
import InfoPage from "@pages/StudentInfoPage/InfoPage";
import AdminUsersList from "@pages/AdminUsersList";
import AdminCreateOrganization from "@pages/AdminCreateOrganization";
import AdminCreateStudent from "@pages/AdminCreateStudent";
import AdminStudentsList from "@pages/AdminStudentList/AdminStudentsList";
import AdminDatabasePage from "@pages/AdminDatabasePage/AdminDatabasePage";
import StudentPdf from "@pages/StudentPdf/StudentPdf";
import CreateOrEditProduct from "@pages/AdminCreateOrEditProduct/CreateOrEditProduct";

const USER_MENU = [
    {name: "Цифровой профиль", path: "/profile", icon_class: "i-person"},
    {
        name: "Мессенджер",
        path: "/profile/messenger",
        icon_class: "i-messenger",
    },
    {
        name: "Рейтинг студентов",
        path: "/profile/rating",
        icon_class: "i-rating",
    },
    {
        name: "Представители компаний",
        path: "/profile/companies",
        icon_class: "i-star",
    },
    {
        name: "Магазин",
        path: "/profile/shop",
        icon_class: "i-basket",
    },

    {
        name: "Карьера",
        path: "/profile/careers",
        icon_class: "i-career",
    },
];

const ADMIN_MENU = [
    /*{
        name: "Список юзеров 2",
        path: "/admin/users-list2",
        icon_class: "",
    },
    {
        name: "Создание/Редактирование Организации",
        path: "/admin/org-create",
        icon_class: "",
    },
    {
        name: "Создание/Редактирование Студента",
        path: "/admin/student-create",
        icon_class: "",
    },*/
    {name: "Компании", path: "/admin", icon_class: "i-companies"},
    {name: "Студенты", path: "/admin/students", icon_class: "i-users"},
    {name: "База", path: "/admin/database", icon_class: "i-cloud"},

    {
        name: "Справочники",
        path: "/admin/catalog",
        icon_class: "i-menu",
    },
    {
        name: "Мессенджер",
        path: "/admin/messenger",
        icon_class: "i-messenger"
    },
    {
        name: "Магазин",
        path: "/admin/shop",
        icon_class: "i-basket",
    },
    {
        name: "Карьера",
        path: "/admin/careers",
        icon_class: "i-career",
    },
];

const App = () => {

    const user = useSelector((state) => state.user.user);

    const isCompany = user.roles.includes("COMPANY");

    const menu = useMemo(() => {
        const rawMenu = [...USER_MENU];
        if (isCompany) {
            rawMenu[0].name = "Профиль компании";
        }else rawMenu[0].name  =  "Цифровой профиль";

        return rawMenu;
    }, [isCompany]);

    useEffect(()=>{
        //reactManifest.update(manifest, "#manifest-placeholder");
    },[])

    return (
        <>
            <Routes>
                <Route path="/" element={<StartPage/>}/>
                <Route path="/login" element={<LoginPage/>}/>
                <Route path="/profile" element={<Layout menu={menu}/>}>
                    <Route
                        path="pdf"
                        element={
                            <RequireAuth roles={["STUDENT", "COMPANY", "ADMIN"]}>
                                <StudentPdf/>
                            </RequireAuth>
                        }
                    />
                    <Route
                        index
                        element={
                            <RequireAuth roles={["STUDENT", "COMPANY", "ADMIN"]}>
                                <ProfilePage/>
                            </RequireAuth>
                        }
                    />
                    <Route
                        path="messenger"
                        element={
                            <RequireAuth roles={["STUDENT", "COMPANY", "ADMIN"]}>
                                <MessengerPage/>
                            </RequireAuth>
                        }
                    />
                    <Route
                        path="info"
                        element={
                            <RequireAuth roles={["STUDENT"]}>
                                <InfoPage/>
                            </RequireAuth>
                        }
                    />
                    <Route
                        index
                        path="rating"
                        element={
                            <RequireAuth roles={["STUDENT", "COMPANY", "ADMIN"]}>
                                <RatingPage/>
                            </RequireAuth>
                        }
                    />
                    <Route
                        path="companies"
                        element={
                            <RequireAuth roles={["STUDENT", "COMPANY", "ADMIN"]}>
                                <CompanyListPage/>
                            </RequireAuth>
                        }
                    />
                    <Route
                        path="portfolio/:studentId"
                        element={
                            <RequireAuth roles={["STUDENT", "COMPANY", "ADMIN"]}>
                                <StudentFromRatingPage/>
                            </RequireAuth>
                        }
                    />
                    <Route
                        path="profile/:companyId"
                        element={
                            <RequireAuth roles={["STUDENT", "COMPANY", "ADMIN"]}>
                                <CompanyFromListPage/>
                            </RequireAuth>
                        }
                    />
                    <Route
                        path="shop"
                        element={
                            <RequireAuth roles={["STUDENT", "COMPANY", "ADMIN"]}>
                                <ShopPage/>
                            </RequireAuth>
                        }
                    />
                    <Route
                        path="view-career/:careerId"
                        element={
                            <RequireAuth roles={["STUDENT", "COMPANY", "ADMIN"]}>
                                <ViewCareerPage/>
                            </RequireAuth>
                        }
                    />

                    <Route
                        path="careers"
                        element={
                            <RequireAuth roles={["STUDENT", "COMPANY", "ADMIN"]}>
                                <CareersPage/>
                            </RequireAuth>
                        }
                    />
                    <Route
                        path="create-career"
                        element={
                            <RequireAuth roles={["COMPANY"]}>
                                <CreateCareerPage/>
                            </RequireAuth>
                        }
                    />
                    <Route
                        path="edit-career/:careerId"
                        element={
                            <RequireAuth roles={["COMPANY"]}>
                                <CreateCareerPage />
                            </RequireAuth>
                        }
                    />
                    <Route
                        path="edit"
                        element={
                            <RequireAuth roles={["STUDENT", "COMPANY", "ADMIN"]}>
                                <EditProfilePage/>
                            </RequireAuth>
                        }
                    />
                    <Route
                        path="shop/:productId"
                        element={
                            <RequireAuth roles={["STUDENT", "COMPANY", "ADMIN"]}>
                                <ShopItem/>
                            </RequireAuth>
                        }
                    />
                </Route>

                <Route path="/admin" element={<Layout menu={ADMIN_MENU}/>}>

                    <Route
                        path="database"
                        element={
                            <RequireAuth roles={["ADMIN"]}>
                                <AdminDatabasePage/>
                            </RequireAuth>
                        }
                    />
                    <Route
                        path="org-create"
                        element={
                            <RequireAuth roles={["ADMIN"]}>
                                <AdminCreateOrganization/>
                            </RequireAuth>
                        }
                    />
                    <Route
                        path="org-create/:organizationId"
                        element={
                            <RequireAuth roles={["ADMIN"]}>
                                <AdminCreateOrganization/>
                            </RequireAuth>
                        }
                    />
                    <Route
                        path="student-create"
                        element={
                            <RequireAuth roles={["ADMIN"]}>
                                <AdminCreateStudent/>
                            </RequireAuth>
                        }
                    />
                    <Route
                        path="student-update/:studentId"
                        element={
                            <RequireAuth roles={["ADMIN"]}>
                                <AdminCreateStudent/>
                            </RequireAuth>
                        }
                    />
                    <Route
                        exact
                        index
                        element={
                            <RequireAuth roles={["ADMIN"]}>
                                <CompanyFromAdminPage/>
                            </RequireAuth>
                        }
                    />
                    <Route
                        path="students"
                        element={
                            <RequireAuth roles={["ADMIN"]}>
                                <AdminStudentsList/>
                            </RequireAuth>
                        }
                    />
                    <Route
                        path="careers"
                        element={
                            <RequireAuth roles={["ADMIN"]}>
                                <AdminCareersPage/>
                            </RequireAuth>
                        }
                    />
                    <Route
                        path="create-career"
                        element={
                            <RequireAuth roles={["ADMIN"]}>
                                <AdminCreateCareerPage/>
                            </RequireAuth>
                        }
                    />
                    <Route
                        path="edit-career/:careerId"
                        element={
                            <RequireAuth roles={["ADMIN"]}>
                                <AdminCreateCareerPage/>
                            </RequireAuth>
                        }
                    />
                    <Route
                        path="view-career/:careerId"
                        element={
                            <RequireAuth roles={["ADMIN"]}>
                                <ViewCareerPage/>
                            </RequireAuth>
                        }
                    />
                    <Route
                        path="catalog"
                        element={
                            <RequireAuth roles={["ADMIN"]}>
                                <CatalogFromAdminPage/>
                            </RequireAuth>
                        }
                    />
                    <Route
                        path="messenger"
                        element={
                            <RequireAuth roles={["STUDENT", "COMPANY", "ADMIN"]}>
                                <MessengerPage/>
                            </RequireAuth>
                        }
                    />
                    {/*<Route
                        path="messenger"
                        element={
                            <RequireAuth roles={["ADMIN"]}>
                                <MessengerFromAdminPage/>
                            </RequireAuth>
                        }
                    />*/}
                    <Route
                        path="shop"
                        element={
                            <RequireAuth roles={["ADMIN"]}>
                                <ShopFromAdminPage/>
                            </RequireAuth>
                        }
                    />
                    <Route
                        path="shop/create"
                        element={
                            <RequireAuth roles={["ADMIN"]}>
                                <CreateOrEditProduct/>
                            </RequireAuth>
                        }
                    />
                    <Route
                        path="shop/edit/:productId"
                        element={
                            <RequireAuth roles={["ADMIN"]}>
                                <CreateOrEditProduct/>
                            </RequireAuth>
                        }
                    />
                </Route>

                <Route path="*" element={<NotfoundPage/>}/>
            </Routes>
            <CookieAccept/>
        </>
    );
};

export default App;
