import "../assets/styles/components/show_feedback.scss";
import React, {useEffect, useState} from "react";

import {useShowFeedback} from "../hooks/useShowFeedback";

import ModalCustom from "./ModalCustom";
import {useSelector} from "react-redux";
import {IsStudent} from "@hooks/useAuth";

const ShowFeedbackForm = ({student, openFeedbackModal}) => {
    const user = useSelector((state) => state.user.user);
    const {feedback,updateMark,saveNewFeedback} = useShowFeedback(student);
    const isCompany = user.id !== student.id;
    const [fullFeedbackModal, setFullFeedbackModal] = useState(false);
    const [fullFeedbackContent, setFullFeedbackContent] = useState({});
    const isStudent = IsStudent();


    const openFullFeedback = (feedback) => {
        setFullFeedbackModal(true);
        setFullFeedbackContent(feedback);
    };

    const getMarkClass = (status) => {
        if(status === 0)
            return {
                class: "i-smile_sad",
                text: "Не соответствует",
            };
        else if(status <= 10)
            return {
                class: "i-smile_ok",
                text: "Недостаточно соответствует",
            };
        else
            return {
                class: "i-smile_joy",
                text: "Соответствует",
            };

    };
    const getCount = (mark,feedbacks) => {
        return feedbacks.filter((el) => el.competenceMark === mark).length;
    }
    const getMarkActive = (templ,mark) =>{
        if(!mark || templ !== mark)
            return "inactive";
    }

    return (
        <div className="show_feedback">
            {feedback.length > 0 ? (
                feedback.map((el) => (
                    <div onClick={() => openFullFeedback(el)} className="shadow_block" key={el.company.companyId}>
                        <h2>{el.company.name}</h2>

                            <div className="short_feedback">
                                <div className="like mark">
                                    <span className="i-smile_joy icon_mark"></span>
                                    {getCount("LIKE",el.competenceFeedbacks)}
                                </div>
                                <div className="middle mark">
                                    <span className="i-smile_ok icon_mark"></span>
                                    {getCount("MIDDLE",el.competenceFeedbacks)}

                                </div>
                                <div className="dislike mark">
                                    <span className="i-smile_sad icon_mark"></span>
                                    {getCount("DISLIKE",el.competenceFeedbacks)}

                                </div>
                            </div>

                        <span
                            className="show_full_feedback"

                        >
                          Подробнее
                          <span className="i-up_right"></span>
                        </span>

                    </div>
                ))
            ) : (
                <div className="empty">
                    Обратная связь на компетенции еще не получена
                </div>
            )}
            {isCompany && (
                <button
                    className="submit_button feedback_button"

                    onClick={() => {
                        openFullFeedback(feedback[0] || null)
                    }}
                >
                    Оставить обратную связь
                </button>
            )}
            {fullFeedbackContent && fullFeedbackContent.company && (
                <ModalCustom
                    title={fullFeedbackContent.company.name}
                    active={fullFeedbackModal}
                    setActive={setFullFeedbackModal}
                >
                    <div className="feedback_full">
                        <table className="list">
                            <tbody>
                            <tr>
                                <td></td>
                               {/* <td>Обратная связь</td>*/}
                                <td>Оценка</td>
                            </tr>
                            {fullFeedbackContent.competenceFeedbacks &&
                                fullFeedbackContent.competenceFeedbacks.map((el,key) => (
                                    <tr key={el.studentCompetence.competence.competenceId} className="line">
                                        <td>{el.studentCompetence.competence.name}</td>
                                        {/*<td>
                                            <span
                                                className={`${
                                                    getMarkClass(el.studentCompetence.competenceScore).class
                                                } icon_mark`}
                                            >
                                              {getMarkClass(el.studentCompetence.competenceScore).text}
                                            </span>
                                        </td>*/}
                                        <td>
                                            <span
                                                onClick={()=> isCompany ? updateMark(key,"LIKE"):  null}
                                            className={`i-smile_joy icon_mark ${getMarkActive("LIKE",el.competenceMark)}`}
                                            >
                                                Соответствует
                                            </span>
                                            <span
                                                onClick={()=>isCompany ? updateMark(key,"MIDDLE"):  null}
                                                className={`i-smile_ok icon_mark ${getMarkActive("MIDDLE",el.competenceMark)}`}
                                            >
                                                Недостаточно соответствует
                                            </span>
                                            <span
                                                onClick={()=>isCompany ? updateMark(key,"DISLIKE") :  null}
                                                className={`i-smile_sad icon_mark ${getMarkActive("DISLIKE",el.competenceMark)}`}
                                            >
                                                Не соответствует
                                            </span>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>

                    </div>
                    {isCompany && (
                        <div className="buttons_block buttons_block-right">
                            <button onClick={saveNewFeedback} className="secondary_button turquoise_button">
                                Сохранить
                            </button>
                        </div>
                    )}

                </ModalCustom>
            )}
        </div>
    );
};
export default ShowFeedbackForm;
