import React, {useEffect} from 'react';
import { Page, Text, View, Document, StyleSheet,Font } from '@react-pdf/renderer';
import {useProfileStudent} from "@hooks/useProfileStudent";
const StudentPdf = () => {
    Font.register({
        family: "Roboto",
        src:
            "https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-medium-webfont.ttf"
    });
    const styles = StyleSheet.create({
        page: {
            fontFamily : "Roboto",
            flexDirection: 'row',
            backgroundColor: '#E4E4E4'
        },
        section: {
            margin: 10,
            padding: 10,
        }
    });

    return(
        <Document>
            <Page size="A4" style={styles.page}>
                <div style={{width:"100%",display:"flex",flexDirection:"column",backgroundColor:"green",height:"100%"}}>
                    <Text>Компетенции за текущий семестр</Text>
                    <View style={styles.section}>
                        <Text>Section #2</Text>
                    </View>
                    <div style={{width:"100%",backgroundColor:"red",height:"100px"}}>
                        1
                    </div>
                </div>

            </Page>
        </Document>
    )

}

export default StudentPdf;
