import "@assets/styles/pages/page_from_admin.scss";

import React from "react";

import ProductsTable from "./components/ProductsTable";
import FilterAccordion from "@pages/CareersPage/components/FilterAccordion";
import Collapse from "@pages/AdminCareersPage/components/Collapse";

const ShopFromAdminPage = () => {
  return (
    <div className="page_from_admin newStyle ">
      <h1>Список товаров</h1>
        <Collapse
            header={"Поиск"}
        >
            <div style={{width: "100%"}}>
                <form action="" method="" className="search-form">
                    <div className="flex">
                        <div style={{flex:1}} className="search-blk create-blk">
                            <label htmlFor="Name">Название</label>
                            <input type="text" placeholder="Толстовка" id="Name"/>
                        </div>
                        <div style={{flex:1}} className="search-blk create-blk">
                            <label htmlFor="Mail">Компания</label>
                            <input type="text" placeholder="СПбПУ" id="Mail"/>
                        </div>
                    </div>
                    <div className="search-btn">
                        <button type="reset" className="btn btn-reset">Сбросить</button>
                        <button type="submit" className="btn btn-blue">Применить</button>
                    </div>
                </form>
            </div>
        </Collapse>
      <div className="shop_block filtered_page_block">

        <div className="shop_content filtered_page_content customTable">
          <ProductsTable />
        </div>
        {/*<FiltersBlock
          opened={!collapseFilter}
          onClose={() => setCollapseFilter(true)}
          onFilterChange={setSelectedFilter}
        />*/}
      </div>
    </div>
  );
};

export default ShopFromAdminPage;
