import React from "react";

import ChatButton from "./ChatButton";
import BlockLoads from "@components/BlockLoads";

const ChatsPanel = ({chats, selectedChat, selectChat}) => {
    return (
        <div className="message_list scroll_element">
            {!chats  &&
                <BlockLoads repeat={5} component={<span className={"message mess-load"}></span>}/>
            }
            {chats && chats.length > 0 && (
                <ul>
                    {chats.map((el) => {
                        return (
                            <ChatButton
                                key={el?.chatId}
                                el={el}
                                selectedChat={selectedChat}
                                selectChat={selectChat}
                            />
                        )

                    })}
                </ul>
            ) }
            {chats && chats.length === 0 && (
                    <div className="empty_list">Пока никого нет</div>
            )}
        </div>
    );
};

export default React.memo(ChatsPanel);
