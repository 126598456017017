import "@assets/styles/pages/shop.scss";

import React, {useEffect, useRef, useState} from "react";
import "@assets/newpages/style.scss"
import EditCareer from "@pages/EditProfilePage/sub-pages/EditCareer/EditCareer";
import ProfileEditor from "@components/organisms/ProfileEditor";
import {NavLink} from "react-router-dom";
import {useSelector} from "react-redux";
import {IsCompany, useAuth} from "@hooks/useAuth";
import {useProfileStudent} from "@hooks/useProfileStudent";
import {useProfileCompany} from "@hooks/useProfileCompany";
import CompanyResponsible from "@components/organisms/ProfileEditor/components/CompanyResponsible";
import Tariff from "@components/organisms/Tariff/Tariff";
import {useVariables} from "@hooks/useVariables";
import {GET_MY_SUBSCRIPTION, GET_SUBSCRIPTION_BY_COMPANY_ID} from "@api/endpoints";
import {usePagination} from "@hooks/usePagination";
import Responses from "@pages/StudentInfoPage/Tabs/Responses/Responses";
import LoginPassword from "@pages/AdminCreateStudent/Tabs/LoginPassword";
import useTabHash from "@hooks/useTabHash";

const EditProfilePage = () => {
    const [myTariff, setMyTariff] = useState();
    const requestGet = useVariables().requestOptionsGET;
    const user = useSelector((state) => state.user.user);
    const { studentData } = useProfileStudent(user.id);
    const [companyId, setCompanyId] = useState()
    const isCompanyContent = IsCompany();

    useEffect(() => {
        if (user && user.id) {
            setCompanyId(user.id)
        }
    }, [user])
    useEffect(() => {
        if (user && user.id && isCompanyContent) {
            fetch(GET_SUBSCRIPTION_BY_COMPANY_ID(user.id), requestGet).then((res) => res.json()).then((data) => {
                setMyTariff(data.content)
            })
        }
    }, [user])
    const tabs = isCompanyContent ? [
        "Основная информация",
         "Тариф",
        "Карьера" ,
        "Ответственные"
    ] : [
        "Основная информация",
        "Отклики на предложения",
        "Логин и пароль",
    ];
    const [activeTab, setActiveTab] = useState(0);
    const [checkIfOpenByHash, setCheckIfOpenByHash] = useState(false);
    const tabsPages = isCompanyContent ? [
        <ProfileEditor/>,
        (myTariff ? <Tariff myTariff={myTariff}/> : null),
        <EditCareer/>,
        <CompanyResponsible/>
    ] : [
        <ProfileEditor/>,
        <Responses/>,
        <LoginPassword/>,
    ]


    useTabHash(activeTab,setActiveTab)

    return (
        <div className="newStyle">
            <NavLink to={"../"} end>
                <a className="btn-back">Редактирование профиля</a>
            </NavLink>
            <div className="tab-block tab-edit">
                <ul className="tabs">
                    {tabs.map((tab, index) => (
                        <li onClick={() => setActiveTab(index)} key={index}
                            className={activeTab === index ? "active" : ""}>
                            {tab}
                        </li>
                    ))}
                </ul>
            </div>
            <div className="edit-profile">
                {tabsPages[activeTab]}
            </div>

        </div>
    );
};

export default EditProfilePage;
