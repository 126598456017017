import React from "react";

import Carousel from "@components/Carousel";
import HeaderBlock from "./HeaderBlock";

import shop from "@assets/images/shop.png";
import vk_group from "@assets/images/vk-group.png";

import { privacyPolicy } from "@constants/links";

const PromotionBlock = () => (
  <div
    className={
      "promo_block" /*showFirst ? "promo_block shrink" : "promo_block"*/
    }
  >
    <HeaderBlock />
    <div className="slider_news">
      <Carousel >
        {/*<div className="carousel_item">
          <div className="news_item">
            <h1>Важно все, что ты делаешь</h1>
          </div>
        </div>*/}
        <div className="carousel_item">
          <div className="news_item">
            <h2>
              Присоединяйся к нашему сообществу ВКонтакте
              {" "}
              <a href="http://vk.com/polykapital" target="_blank">
                «ПолиКапитал»
              </a>
            </h2>
            <p>
              будь в курсе всех обновлений, полезных мероприятий и эксклюзивных предложений! Не пропусти ничего важного!
            </p>
            <div className="news_image">
              <img src={vk_group} alt="vk_group" />
            </div>
          </div>
        </div>
        <div className="carousel_item">
          <div className="news_item">
            <h2>Цифровое портфолио уже получили более 4000 студентов!</h2>
            <p>Собери свои достижения и пополни портфолио – это твой шаг к успешному будущему!</p>
            {/*<p>
              Если тоже хочешь принять участие в пользовательском тестировании,
              пиши на <a href="mailto: polykp@spbstu.ru">polykp@spbstu.ru</a>
            </p>*/}
          </div>
        </div>
        <div className="carousel_item">
          <div className="news_item">
            <h2>Зарабатывай поликоины и участвуй в аукционах!</h2>
            <p>Показывай свои успехи, копи баллы и выигрывай классный мерч от наших партнеров. Твоя активность вознаграждается!</p>
            {/*<div className="news_image">
              <img src={shop} alt="shop" />
            </div>*/}
          </div>
        </div>
        <div className="carousel_item">
          <div className="news_item">
            <h2>Начни карьеру мечты с «ПолиБиржей»!
            </h2>
            <p>Найди подходящую вакансию, напиши работодателю напрямую через чат и получи ответ из первых рук. Твое будущее – в твоих руках!
            </p>
            {/*<div className="news_image">
              <img src={shop} alt="shop" />
            </div>*/}
          </div>
        </div>
        <div className="carousel_item">
          <div className="news_item">
            <h2>Важно все, что ты делаешь!</h2>
            <p>
              Каждое достижение, даже самое маленькое, ведет к большому успеху. Собирай опыт, улучшай свои навыки и становись лучше с каждым шагом вместе с ПолиКапиталом!
            </p>
          </div>
        </div>
      </Carousel>
    </div>
    <div className="links_from_form">
      <p>
        По всем вопросам пишите на почту{" "}
        <a href="mailto: polykp@spbstu.ru">polykp@spbstu.ru</a>
      </p>
      <p>
        <a className="policy" href={privacyPolicy} target={"_blank"}>
          Политика конфиденциальности
        </a>
      </p>
    </div>
  </div>
);

export default React.memo(PromotionBlock);
