import React, {useEffect, useState} from 'react';
import EditOrCreateModal from "@pages/AdminCreateOrganization/Tabs/Responsible/EditOrCreateModal";
import ModalCustom from "@components/ModalCustom";
import CustomSelect from "@components/CustomSelect";
import CustomInput from "@components/CustomInput";
import change from "@assets/newpages/img/change-info.svg"
import {useVariables} from "@hooks/useVariables";
import {GET_SUBSCRIPTIONS, UPDATE_COMPANY_SUBSCRIPTION} from "@api/endpoints";
import {useSelector} from "react-redux";
import {getContactWord} from "@utils/other";

const Tariff = ({myTariff}) => {
    const user = useSelector((state) => state.user.user);
    const [tariffs,setTariffs] = useState();
    const [openModal, setOpenModal] = useState(false);
    const [successSend, setSuccessSend] = useState(false);
    const [modalComment, setModalComment] = useState("");
    const [selectedTariff, setSelectedTariff] = useState(null);
    const [selectValue,setSelectValue] = useState(null);
    const requestGet = useVariables().requestOptionsGET
    const requsetPost = useVariables().constRequestOptionsPOST
    const prices = {
        4:"12 500",
        3:"4 500",
        2:"2 000",
        1:"0"
    }
    useEffect(()=>{
        console.log(myTariff)
        fetch(GET_SUBSCRIPTIONS,requestGet).then(res => res.json()).then(data => {
            setTariffs(data.content.sort((a,b)=>a.subscriptionId - b.subscriptionId))
        })
    },[myTariff])

    const openModal_ = id =>{
        if(tariffs){
            let tar = tariffs.find(x=>x.subscriptionId === id);
            setSelectedTariff({value: tar.subscriptionId, label: tar.subscriptionName})
            setModalComment("")
            setSelectValue(id)
            setSuccessSend(false)
            setOpenModal(true)
        }

    }
   const getActiveSubScribe = (id) =>{
        if((!myTariff || myTariff.length === 0) && id === 1)
            return "active";
        if(myTariff && myTariff[0].subscriptionId === id)
            return "active";
        return  "";
   }
    const edit = () =>{
        let cfg = requsetPost({comment: modalComment, subscriptionId: selectValue})
        fetch(UPDATE_COMPANY_SUBSCRIPTION(user.id),cfg).then(res => res.json())
        setSuccessSend(true)
    }
    return (
        <div className="tariffstl">
            <div className="table table-tarif">
                <span className="question"></span>
                <table>
                    <thead>
                        <tr>
                            <th>Название тарифа</th>
                            <th>Дата покупки</th>
                            <th>Дата окончания</th>
                            <th>Пользователи</th>
                            <th>Предложения</th>
                            <th>Чаты</th>
                        </tr>

                    </thead>
                    <tbody>
                    {(myTariff && myTariff.length > 0) ?
                        myTariff.map((tariff, index) => (
                            <tr style={{display:"table-row"}} key={index}>
                                <td data-label="Текущий тариф:">{tariff.subscriptionName}</td>
                                <td data-label="Дата покупки:">{tariff.startDate === "1970-01-01" ? "" : tariff.startDate}</td>
                                <td data-label="Дата окончания:">{tariff.endDate === null ? "" : tariff.endDate}</td>
                                <td data-label="Пользователи:">{tariff.usersCount} из {tariff.usersMaxCount}</td>
                                <td data-label="Предложения:">{tariff.offersCount} из {tariff.offersMaxCount}</td>
                                <td data-label="Чаты:">{tariff.chatsCount} из {tariff.chatMaxCount}</td>
                            </tr>
                        ))
                        :
                        <td colSpan={6}>История пустая</td>

                    }

                    </tbody>
                </table>
            </div>
            <div className="tarif-block">
                {tariffs && tariffs.length > 0 && tariffs.map((tariff, index) => (
                    <div key={index} className={`tarif-blk ${getActiveSubScribe(tariff.subscriptionId)}`}>
                        <div className="tarif-title">
                            <span>{tariff.subscriptionName}</span>
                        </div>
                        <ul>
                            <li>{tariff.usersMaxCount} пользователь от компании</li>
                            <li>{tariff.offersMaxCount} карьерное предложение в месяц</li>
                            <li>{tariff.chatsMaxCount} {getContactWord(tariff.chatsMaxCount)} / рейтинг + отклики</li>
                        </ul>
                        <div className="tarif-price">
                            <span>{prices[tariff.subscriptionId]}</span>
                            <p>руб./мес</p>
                        </div>
                        <button onClick={getActiveSubScribe(tariff.subscriptionId) ? null : () => openModal_(tariff.subscriptionId)}
                                className={`btn ${getActiveSubScribe(tariff.subscriptionId) ? "disabled" : 'btn-change'}`}>
                            {getActiveSubScribe(tariff.subscriptionId) ? "Текущий тариф" : "Оставить заявку"}
                        </button>
                    </div>

                ))}

            </div>

            <ModalCustom
                onClose={()=>setSuccessSend(false)}
                title={"Заявка на смену тарифа"}
                active={openModal}
                className={"autoWidth"}
                setActive={setOpenModal}
            >
                {!successSend &&
                    <div className="change-tarif">
                        <table>
                            <tbody>
                            <tr>
                                <th>Организация</th>
                                <th>Сотрудник</th>
                            </tr>
                            <tr>
                                <td data-label="Организация:">{user?.name ? user?.name : (user?.longName ? user?.longName : "Нет имени")}</td>
                                {user?.contactPersonList &&
                                    <td data-label="Сотрудник:">{user?.contactPersonList[0].secondName + " " + user?.contactPersonList[0].firstName + " " + (user?.contactPersonList[0].middleName ? user?.contactPersonList[0].middleName : "")}</td>
                                }

                            </tr>
                            </tbody>
                        </table>
                        <div className="change-tarif__inp">
                            <span>Тариф</span>
                            <div className="select-block">
                                <CustomSelect
                                    onChange={(x)=>setSelectValue(x)}
                                    placeholder={"Выберите тариф"}
                                    options={tariffs && tariffs.map(x=>{
                                        return {
                                            label: x.subscriptionName,
                                            value: x.subscriptionId
                                        }
                                    })}
                                    refValue={selectedTariff}
                                />
                            </div>
                        </div>
                        <div className="change-tarif__inp">
                            <label>Комментарий</label>
                            <CustomInput
                                name="vacancy_description"
                                type="textarea"
                                placeholder="Описание"
                            />
                        </div>
                        <div className="table-btn">
                            <button onClick={edit} type="submit" className="btn btn-blue">Отправить заявку</button>
                        </div>
                    </div>
                }
                {successSend &&
                    <div className="change-tarif change-tarif__success">
                        <img src={change} alt="" className="change-img"/>
                            <div className="change-info">
                                <span>Спасибо за обращение!</span>
                                <p>Ваш запрос был успешно отправлен. В ближайшее время для уточнения деталей с вами
                                    свяжется наш специалист.</p>
                            </div>
                        <div className="table-btn">
                            <button onClick={()=>{
                                setSuccessSend(false)
                                setOpenModal(false)
                            }} type="submit" className="btn btn-blue">Назад в профиль</button>
                        </div>
                    </div>
                }


            </ModalCustom>
        </div>

    )

}
export default Tariff;