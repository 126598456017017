import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import { usePagination } from "@hooks/usePagination";
import { useVariables } from "@hooks/useVariables";

import {
  GET_ALL_CATEGORIES,
  GET_ALL_COMPETENCIES, GET_ALL_COMPETENCIES_PAGINATION,
  GET_RATING,
} from "@api/endpoints";

export const useRating = () => {
  const navigate = useNavigate();
  const user = useSelector((state) => state.user.user);

  const [searchParams, setSearchParams] = useState({ all: true });

  const requestOptionsPOST = useVariables(searchParams).requestOptionsPOST;
  const requestOptionsGET = useVariables(searchParams).requestOptionsGET;
  const constRequestOptionsGET = useVariables(searchParams).constRequestOptionsGET;

  const ratingData = usePagination(
    GET_RATING,
      requestOptionsGET,
    "userId,asc",null,searchParams,true
  );
  const rating = ratingData.data;

  const [competencies, setCompetencies] = useState([]);
  const [categories, setCategories] = useState([]);

  const [isCustomSelectActive, setIsCustomSelectActive] = useState({
    competencies: false,
    polycoin: false,
  });

  const applyFilters = (filters) => {
    setSearchParams((prev) => {
      return {
        ...prev,
        ...filters,
      };
    });
  };
  const clearFilters = () => {
    setSearchParams({ });
    ratingData.refresh()
  }

  const getCompetencies = () => {
    fetch(GET_ALL_COMPETENCIES_PAGINATION(0,100,"",{}), requestOptionsGET)
      .then((response) => response.json())
      .then((json) => {
        const options = makeOptionsForSelect("competencies", json.content);
        setCompetencies(options);
      })
      .catch((e) => console.log("Произошла ошибка",e));
  };

  const getCategories = () => {
    fetch(GET_ALL_CATEGORIES, requestOptionsGET)
      .then((response) => response.json())
      .then((json) => {
        const options = makeOptionsForSelect("categories", json);
        setCategories(options);
      })
      .catch(() => console.log("Произошла ошибка"));
  };

  const makeOptionsForSelect = (type, data) => {
    switch (type) {
      case "competencies":
        return data.map((el) => {
          return {
            label: el.name,
            value: el.competenceId,
          };
        });
      case "categories":
        return data.map((el) => {
          return {
            label: el.name,
            value: el.id,
          };
        });
      default:
        return [];
    }
  };

  useEffect(() => {
    getCompetencies();
    getCategories();
  }, []);

  const redirectStudentPage = (studentId) => {
    /*if (user.roles.includes("COMPANY")) {*/
      navigate(`../portfolio/${studentId}`,{state:{target:"_blank"}});
    /*}*/
  };

  const changeCompetenceSortHandler = (type, value) => {
    switch (type) {
      case "competencies":
        setIsCustomSelectActive(
          value.length > 0
            ? { competencies: false, polycoin: true }
            : { competencies: false, polycoin: false },
        );
        return setSearchParams((prev) => {
          let data = {...prev};

          if(data.hasOwnProperty("activityId"))
            delete data.activityId;
          if(value.length === 0)
            delete data.competenceId;
          return value.length > 0
            ? { ...data, competenceId: value }
            : { ...data, all: true};
        });
      case "categories":
        setIsCustomSelectActive(
          value
            ? { polycoin: false, competencies: true }
            : { polycoin: false, competencies: false },
        );

        return setSearchParams((prev) => {
          let data = {...prev};

          if(data.hasOwnProperty("competenceId"))
            delete data.competenceId;
          if(value.length === 0)
            delete data.activityId;
          return value
            ? { ...data, activityId: value}
            : { ...data, all: true};
        });
      default:
        return [];
    }
  };

  return {
    loading: ratingData.loading,
    rating,
    competencies,
    categories,
    clearFilters,
    applyFilters,
    redirectStudentPage,
    changeCompetenceSortHandler,
    isCustomSelectActive,
  };
};
