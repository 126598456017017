import { useSelector } from "react-redux";

import { usePagination } from "./usePagination";
import { useVariables } from "./useVariables";

import { GET_ALL_PRODUCT_LIST, GET_PRODUCT_LIST } from "@api/endpoints";
import { useMemo } from "react";

export const useShop = (params) => {
  console.log("### useShop", params);
  const { productStatus, name } = useMemo(() => params || {}, [params]);
  const user = useSelector((state) => state.user.user);
  const isCompany = user.roles.includes("COMPANY");

  console.log("### productStatus", productStatus);

  const searchParams = useMemo(() => {
    const query = {};

    if (productStatus) {
      query.productStatus = productStatus;
    } else {
      query.isAll = true;
    }

    if (name) {
      query.name = name;
    }

    return query;
  }, [productStatus, name]);
  console.log("### searchParams", searchParams);

  const requestOptionsPOST = useVariables(searchParams).requestOptionsPOST;
  console.log("### requestOptionsPOST", requestOptionsPOST);
  const { data: products, refresh,loading } = usePagination(
      GET_PRODUCT_LIST,
    requestOptionsPOST,
    "productId,desc",
  );

  // useEffect(() => {
  //   console.log("### products", products);
  // }, [products]);

  return {
    loading,
    products,
    refresh,
  };
};
