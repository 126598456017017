import React, {Component, useEffect, useMemo, useRef, useState} from 'react';
import {NavLink, useLocation, useParams} from "react-router-dom";
import {usePagination} from "@hooks/usePagination";
import {ADMIN_COMPANY, ADMIN_STUDENTS, DOWNLOAD_FILE, GET_COMPANY_PROFILES_V2} from "@api/endpoints";
import {useVariables} from "@hooks/useVariables";
import CustomSelect from "@components/CustomSelect";
import {studentsLanguages} from "@utils/languageList";
import {toast} from "react-toastify";
import ShopActions from "@store/asyncActions/ShopActions";
import {useDownloadFile} from "@hooks/useDownloadFile";
import {usePreviewFile} from "@hooks/usePreviewFile";
import ModalCustom from "@components/ModalCustom";
import CropModalContent from "@components/organisms/ProfileEditor/components/CropModalContent";
import {useProfileStudent} from "@hooks/useProfileStudent";

const CreateOrEditProduct = ({}) =>{
    const {productId} = useParams();
    const {
        selectedFile: selectedAvatar,
        setSelectedFile: setSelectedAvatar,
        handleFileChange: handleImageChange,
    } = usePreviewFile(
        null,
        "image.*",
    );
    const {createProduct,uploadAvatar} = ShopActions();
    const [avatarCropModalActive, setAvatarCropModalActive] = useState(false);
    const [selectedAvatarImage, setSelectedAvatarImage] = useState();
    const img = useDownloadFile(DOWNLOAD_FILE, product?.avatarId).file;
    const previousLocationPathname = useLocation().state?.previousLocationPathname
    const [info, setInfo] = useState(null)
    const [status,setStatus] = useState()
    const fileIdRef = useRef();
    const requestGet = useVariables().requestOptionsGET;
    useEffect(() => {
        fetch(ADMIN_COMPANY.GET_INFORMATION, requestGet).then(res => res.json()).then(res => setInfo(res))
    }, [])
    const product = useMemo(() => {
        if(productId){

        }
    },[productId])
    const [selectedCompanies,setSelectedCompanies] = useState([])
    const {data:companies} = usePagination(GET_COMPANY_PROFILES_V2,requestGet,"" );
    const formRef = useRef()
    const statuses = [
        {label:"Опубликован",value:"ACTUAL"},
        {label:"Черновик",value:"DRAFT"},
        {label:"Архив",value:"ARCHIVE"},
    ]
    const submitForm = (e) => {
        e.preventDefault()
        const formData = new FormData(formRef.current)
        const formObject = Object.fromEntries(formData.entries())
        formObject.productStatus = JSON.parse(formObject.productStatus)?.value
        formObject.companyId = JSON.parse(formObject.companyId)?.value
        if(!formObject.name || !formObject.companyId || !formObject.productStatus){
            toast.error("Заполните все поля", {
                position: toast.POSITION.TOP_CENTER,
            });
            return false;
        }
        createProduct(formObject).then(res => res.json()).then(res=>{
            if(res.error || res.status === "Error"){
                toast.error(res.message[0],{
                    position: toast.POSITION.TOP_CENTER,
                });
                return false;
            }else{
                toast.success("Товар добавлен",{
                    position: toast.POSITION.TOP_CENTER,
                });
                if(selectedAvatar){
                    uploadAvatar(selectedAvatar,res.productId)
                }
            }
        }).catch(()=>{
            toast.error("Ошибка при добавлении товара",{
                position: toast.POSITION.TOP_CENTER})
        });

        return false;
    }

    const handleAvatarFileChange = (e) => {
        const file = e.target.files[0];
        if (file && file.type.match("image.*")) {
            const reader = new FileReader();
            reader.onload = (e) => {
                setSelectedAvatarImage({url: e.target.result});
            };
            reader.readAsDataURL(file);
        }
    };
    useEffect(() => {
        if (selectedAvatarImage) {
            setAvatarCropModalActive(true);
        }
    }, [selectedAvatarImage]);

    const [croppedImage, setCroppedImage] = useState();
    useEffect(() => {
        if (croppedImage) {
            setSelectedAvatar(croppedImage);
        }
    }, [croppedImage]);

    return (
        <div className="newStyle" >
            <NavLink to={previousLocationPathname ? previousLocationPathname : -1} end className="btn-back">Добавление товара</NavLink>
            <div className="add-tovar" style={{paddingTop:20}}>
                <div className="add-photo product">
                    {((selectedAvatar) ||  (img && product?.avatarId)) &&
                        <img src={selectedAvatar ? selectedAvatar : img} alt="avatar" />
                    }
                    <span>Выберите файл или перетащите сюда</span>
                    <input onChange={handleAvatarFileChange} type="file" title="Выберите файл или перетащите сюда"/>
                    <input type={"hidden"} ref={fileIdRef}/>
                </div>
                <div className="tovar-info">
                    <form ref={formRef} onSubmit={submitForm} className="search-form">
                        <div className="flex">
                            <div className="search-blk create-blk">
                                <label htmlFor="TovarName">Название товара</label>
                                <input name={"name"} type="text" placeholder="Футболка" id="TovarName"/>
                            </div>
                            <div className="search-blk create-blk">
                                <label htmlFor="Institute">Организация</label>
                                <CustomSelect
                                    placeholder={"Выберите организацию"}
                                    //refValue={selected(studentsFilters.instituteId || [],"instituteId")}
                                    onChange={(value) => setSelectedCompanies(value)}
                                    name="companyId"
                                    options={companies.map((item) => {
                                        return {label: item.name ? item.name : item.longName, value: item.companyId}
                                    })}
                                />
                            </div>
                                <div className="search-blk create-blk">
                                    <label htmlFor="Status">Статус</label>
                                    <CustomSelect
                                        placeholder={"Статус"}
                                        onChange={(value) => setStatus(value)}
                                        refValue={status && {value: status, label: statuses.find(item => item.value === status).label}}
                                        name="productStatus"
                                        options={statuses}
                                    />
                                </div>
                            <div className="search-blk create-blk search-blk-text">
                                <label htmlFor="Descript">Описание</label>
                                <textarea  name="description" id="Descript" placeholder="ivanov.ii"></textarea>
                            </div>
                        </div>
                        <div style={{marginTop:20}}  className="tovar-info-blk">
                            <div className="flex">
                                <h3>Аукцион</h3>
                                <a href="#" className="btn btn-arrow">Запланировать</a>
                            </div>
                        </div>
                        <div className="table-btn student-btn">
                            <button className="btn btn-grey">Отменить</button>
                            <button type="submit" className="btn btn-blue" value={""}>Сохранить</button>
                        </div>
                    </form>

                </div>
            </div>
            <ModalCustom
                title="Подготовка Аватара"
                active={avatarCropModalActive}
                setActive={setAvatarCropModalActive}
            >
                <CropModalContent
                    image={selectedAvatarImage ? selectedAvatarImage.url : undefined}
                    onClose={(croppedImage) => {
                        setCroppedImage(croppedImage);
                        setAvatarCropModalActive(false);
                    }}
                />
            </ModalCustom>
        </div>
    )
}
export default CreateOrEditProduct;