import React, {useState} from 'react';
import {usePagination} from "@hooks/usePagination";
import {GET_COMPANIES_ACTIVITIES_FROM_COMPANY} from "@api/endpoints";
import {useVariables} from "@hooks/useVariables";
import Collapse from "@pages/AdminCareersPage/components/Collapse";

const Filters = ({setOpenedFilters,handleApplyFilters,handleClearFilters}) => {
    const requestGet = useVariables().requestOptionsGET;
    const [filtersActivity, setFiltersActivity] = useState({name:""});
    const activities = usePagination(GET_COMPANIES_ACTIVITIES_FROM_COMPANY, requestGet, "", "activitiesFilter",filtersActivity);
    const [selectedActivities, setSelectedActivities] = useState([]);
    const isSelected = (id) => {
        return selectedActivities.findIndex(x => x.activityId === id) !== -1;
    }
    const addActivity = (item) => {
        if (!isSelected(item.activityId)) {
            setSelectedActivities(prevState => [...prevState, item]);
        } else
            setSelectedActivities(prevState => prevState.filter(x => x.activityId !== item.activityId));

    }
    const clear = () => {
        setSelectedActivities([]);
        handleClearFilters();
    }
    return (
        <div className="filter-block">
            <div className="flex flex-filter">
                <h2 className="filter-title">Фильтры</h2>
                <button className="filters" onClick={() => setOpenedFilters(prevState => !prevState)}>Свернуть</button>
            </div>

            {/*<div className="filter-blk">
                <span className="title">Карьера</span>
                <label className="checkbox-ios">
                    <input type="checkbox"/>
                    <span className="checkbox-ios-switch"></span>
                    <p>Есть открытые предложения</p>
                </label>
            </div>*/}
            <Collapse header={"Направление"} span>
                <div className="search">
                    <input onChange={(e) => setFiltersActivity(prevState => ({...prevState,name: e.target.value}))} type="text" placeholder="Поиск"/>
                </div>
                <div className="filtr-blk" style={{width: "100%"}} id={""}>
                    <div className="check-block" id={"activitiesFilter"}>
                        {activities.data.map((item, index) => (
                            <span onClick={() => addActivity(item)} key={index} className="option-item form-check">
                            <input className="checkbox" type="checkbox" checked={isSelected(item.activityId)}
                                   name="checkbox"
                                   data-name={item.name}/>
                            <span className="checkbox-custom"></span>
                            <span className="label">{item.name}</span>
                        </span>
                        ))}

                    </div>
                    <div className="check-choise check-option-block">
                        {selectedActivities.map((item, index) => (
                            <div className="check-blk" key={index}>
                                <span>{item.name}</span>
                                <button type="button"
                                        onClick={() => setSelectedActivities(prevState => prevState.filter(x => x.activityId !== item.activityId))}
                                        className="btn-close" aria-label="Close"></button>
                            </div>
                        ))}
                    </div>
                </div>
            </Collapse>


            <div className="filter-btn">
                <button className="btn btn-blue" onClick={()=>handleApplyFilters({activities:selectedActivities})}>Применить</button>
                <button className="btn btn-grey" onClick={clear}>Сбросить всё</button>
            </div>
        </div>
    )

}
export default Filters;