import "@assets/styles/components/profile_editor.scss";

import React, {useEffect, useMemo, useRef, useState} from "react";
import {Tooltip} from "react-tooltip";
import {useDispatch, useSelector} from "react-redux";
import {toast} from "react-toastify";
import styled from "styled-components";

import {useVariables} from "@hooks/useVariables";
import {usePreviewFile} from "@hooks/usePreviewFile";
import {useDownloadFile} from "@hooks/useDownloadFile";

import {updateUserContactListAction, updateUserProfileAction} from "@store/reducers/userReducer";

import CustomInput from "@components/CustomInput";
import ModalCustom from "@components/ModalCustom";
import CropModalContent from "./components/CropModalContent";
import DefaultAvatarListItem from "./components/DefaultAvatarListItem";
import ProfessionsSelector from "./components/ProfessionsSelector";

import {
    DOWNLOAD_FILE, GET_COMPANIES_ACTIVITIES, GET_COMPANIES_ACTIVITIES_FROM_COMPANY,
    GET_DEFAULT_AVATARS, GET_PROFESSIONS, GET_PROFESSIONS_V2,
    UPDATE_AVATAR, UPDATE_AVTAR_V2,
    UPDATE_COMPANY_CONTACT_PERSON,
    UPDATE_COMPANY_MAIN, UPDATE_COMPANY_MAIN_V2,
    UPDATE_COMPANY_PROFESSIONS, UPDATE_STUDENT_PROFILE,
} from "@api/endpoints";
import {
    updateCompanyContactsAction,
    updateCompanyProfessionsActions,
    updateCompanyProfileAction
} from "@store/reducers/companyReducer";
import {useProfileCompany} from "@hooks/useProfileCompany";
import CustomSelect from "@components/CustomSelect";
import {usePagination} from "@hooks/usePagination";
import CompanyResponsible from "@components/organisms/ProfileEditor/components/CompanyResponsible";
import {studentsLanguages} from "@utils/languageList";
import StudentBlock from "@pages/EditProfilePage/sub-pages/StudentBlock";

const ProfileEditor = ({onClose}) => {
    const user = useSelector((state) => state.user.user);
    const requestOptionsGET = useVariables().requestOptionsGET;
    const isCompany = user.roles.includes("COMPANY");
    const searchRef = useRef(null);
    const oldAvatar = useDownloadFile(DOWNLOAD_FILE, user.avatarId);
    const requestGet = useVariables().requestOptionsGET;
    const requestPut = useVariables().constRequestOptionsPUT;
    const activityIds = user.activities ? [...user.activities] : null;
    const [limit, setLimit] = useState({size: 1000})
    const [selectedActivityIds, setSelectedActivities] = useState(user.activities ? [...user.activities.map(x => x.activityId)] : [])
    const [selectedProfession, setSelectedProfession] = useState([]);
    const selectedProfessionRef = useRef();
    selectedProfessionRef.current = selectedProfession;
    const professions = usePagination(GET_PROFESSIONS, requestGet, "", "profsRef", limit).data;
    const activities = usePagination(GET_COMPANIES_ACTIVITIES_FROM_COMPANY, requestGet, "activityId,desc", null, limit).data;
    const formRef = useRef()
    const [defaultAvatars, setDefaultAvatars] = useState([]);
    const defaultAvatarFirst = useDownloadFile(
        DOWNLOAD_FILE,
        defaultAvatars[0]?.attachmentId,
    ).file;

    const {
        selectedFile: selectedAvatar,
        setSelectedFile: setSelectedAvatar,
        handleFileChange: handleImageChange,
    } = usePreviewFile(
        user.newAvatar || oldAvatar?.file || defaultAvatarFirst,
        "image.*",
    );

    useEffect(() => {
        if (user) {
            setSelectedProfession(user.professions?.map(x => x.hasOwnProperty("id") ? x.id : x.professionId));
            setSelectedActivities(user.activities?.map(x => x.activityId));
        }
    }, [user])

    useEffect(() => {
        if (!user.newAvatar) {
            setSelectedAvatar(oldAvatar?.file);
        }
    }, [oldAvatar?.file]);

    const defaultUserProfessions = useMemo(
        () => user.professions?.filter(({userSelected}) => !userSelected),
        [user],
    ) || [];
    const [selectedProfessions, setSelectedProfessions] = useState(
        user.professions?.filter(({userSelected}) => userSelected) || []
    );

    const userProfessions = useMemo(
        () => [...defaultUserProfessions, ...selectedProfessions],
        [defaultUserProfessions, selectedProfessions],
    );
    useEffect(() => {
    }, [defaultUserProfessions])

    const deleteProfession = (id) => {
        setSelectedProfessions((prev) =>
            prev.filter((profession) => profession.id !== id),
        );
    };
    useEffect(() => {
        const keyDownHandler = (event) => {
            if (event.key === "Enter") {
                if (document.activeElement === searchRef.current) {
                    event.preventDefault();
                    return false;
                }
                onClose();
                return true;
            }
        };

        document.addEventListener("keydown", keyDownHandler);

        return () => {
            document.removeEventListener("keydown", keyDownHandler);
        };
    }, []);
    const saveProfileChanges = (e) => {
        const form = new FormData(e);

        saveChanges(form);
    };

    const saveChanges = (form) => {
        const userId = user.id;
        if (isCompany) {
            const contactPersonId = user.contactPersonList[0].contactPersonId;

            const main = {
                description: form.get("description"),
                site: form.get("site"),
                requisites: form.get("requisites"),
                inn: user.inn,
                activityIds: selectedActivityIds,
                professionIds: selectedProfession,
                name: form.get("name"),
                longName: form.get("longName"),
                ogrn: form.get("ogrn"),
            };

            const contactPerson = {
                firstName: form.get("firstName"),
                secondName: form.get("secondName"),
                middleName: form.get("middleName"),
                email: form.get("email"),
                phoneNumber: form.get("phoneNumber"),
                position: form.get("position"),
            };

            const requestOptionsMain = {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `${user.type} ${user.accessToken}`,
                },
                body: JSON.stringify(main),
            };

            /* const requestOptionsContactPerson = {
                 method: "POST",
                 headers: {
                     "Content-Type": "application/json",
                     Authorization: `${user.type} ${user.accessToken}`,
                 },
                 body: JSON.stringify(contactPerson),
             };*/

            updateCompanyMain(userId, requestOptionsMain);
            //updateCompanyContactPerson(contactPersonId, requestOptionsContactPerson);
        }else {
            fetch(UPDATE_STUDENT_PROFILE(user.id),requestPut({professionIds:selectedProfessionRef.current}))

        }
        const requestOptionsProfession = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `${user.type} ${user.accessToken}`,
            },
            body: JSON.stringify({ids: selectedProfessions}),
        };
        const updateCompanyProfessions = (companyId, requestOptions) => {
            fetch(UPDATE_COMPANY_PROFESSIONS(companyId), requestOptions)
                .then((response) => {
                    if (response.ok) {
                        const defaultUserProfessions = user.professions.filter(
                            (profession) => !profession.userSelected,
                        );


                        // dispatch(updateUserProfileAction({ professions: userProfessions }));

                        toast.success("Изменения профессий сохранены", {
                            position: toast.POSITION.TOP_CENTER,
                        });

                        onClose?.();

                        return response.json();
                    } else {
                        throw true;
                    }
                })
                .catch((error) => {
                    console.log(error);
                    toast.error("Ошибка при сохранение профессий", {
                        position: toast.POSITION.TOP_CENTER,
                    });
                });
        };
        /*console.log({
            professions: professions.filter(x=>selectedProfessionRef.current.includes(x.id)),
            activities:activities.filter(x => selectedActivityIds.includes(x.activityId))
        })*/

        dispatch(updateUserProfileAction({
            newAvatar: selectedAvatar ? selectedAvatar : user.newAvatar,
            professions: professions.filter(x => selectedProfessionRef.current.includes(x.professionId)),
            activities: selectedActivityIds && activities.filter(x => selectedActivityIds.includes(x.activityId))
        }))
        //updateCompanyProfessions(userId, requestOptionsProfession);
        toast.success("Изменения сохранены", {
            position: toast.POSITION.TOP_CENTER,
        });
        if (selectedAvatar && selectedAvatarImage) {
            fetch(selectedAvatar)
                .then((response) => response.blob())
                .then((blob) => {
                    // Update the formData object
                    const formData = new FormData();
                    formData.append("file", blob);

                    const requestOptionsAvatar = {
                        method: "POST",
                        headers: {
                            // "Content-Type": "multipart/form-data",
                            Authorization: `${user.type} ${user.accessToken}`,
                        },
                        body: formData,
                    };

                    updateAvatar(userId, requestOptionsAvatar);
                });
        }
    };

    const updateCompanyMain = (companyId, requestOptions) => {
        fetch(UPDATE_COMPANY_MAIN_V2(companyId), requestOptions).then(r => {
            dispatch(updateUserProfileAction({...JSON.parse(requestOptions.body)}));
            //dispatch(updateCompanyProfileAction({...JSON.parse(requestOptions.body)}))
        }).catch(() =>
            console.log("Не удается подключиться к серверу"),
        );
    };

    const updateCompanyContactPerson = (contactPersonId, requestOptions) => {
        fetch(UPDATE_COMPANY_CONTACT_PERSON(contactPersonId), requestOptions).then(r => {
            dispatch(updateUserContactListAction({contactPersonId, data: JSON.parse(requestOptions.body)}));
            dispatch(updateCompanyContactsAction({contactPersonId, data: JSON.parse(requestOptions.body)}));
        }).catch(
            () => console.log("Не удается подключиться к серверу"),
        );
    };

    const dispatch = useDispatch();
    const updateAvatar = (companyId, requestOptions) => {
        fetch(UPDATE_AVTAR_V2(companyId), requestOptions)
            .then((response) => {
                if (response.ok) {

                    dispatch(updateUserProfileAction({avatar:selectedAvatar,newAvatar: selectedAvatar}));
                    toast.success("Изменения аватара сохранены", {
                        position: toast.POSITION.TOP_CENTER,
                    });
                    setSelectedAvatarImage(null)
                    // onClose?.();
                    return response.json();
                } else {
                    throw true;
                }
            })

            .catch((error) => {
                toast.error("Ошибка при обновление аватара", {
                    position: toast.POSITION.TOP_CENTER,
                });
            });
    };

    const getDefaultAvatars = () => {
        fetch(GET_DEFAULT_AVATARS, requestOptionsGET)
            .then((response) => response.json())
            .then((json) => setDefaultAvatars(json))
            .catch(() => console.log("Произошла ошибка"));
    };

    useEffect(() => {
        getDefaultAvatars();
    }, []);

    const [avatarCropModalActive, setAvatarCropModalActive] = useState(false);
    const [selectedAvatarImage, setSelectedAvatarImage] = useState();
    const handleAvatarFileChange = (e) => {
        const file = e.target.files[0];
        if (file && file.type.match("image.*")) {
            const reader = new FileReader();
            reader.onload = (e) => {
                setSelectedAvatarImage({url: e.target.result});
            };
            reader.readAsDataURL(file);
        }
    };

    const [croppedImage, setCroppedImage] = useState();
    useEffect(() => {
        if (selectedAvatarImage) {
            setAvatarCropModalActive(true);
        }
    }, [selectedAvatarImage]);

    useEffect(() => {
        if (croppedImage) {
            setSelectedAvatar(croppedImage);
        }
    }, [croppedImage]);

    const getDefaultActivity = () => {
        if (!activities || !selectedActivityIds)
            return null;
        let act = activities.filter(x => selectedActivityIds.includes(x.activityId));
        if (act)
            return act.map((comp) => {
                return {label: comp.name, value: comp.activityId}
            })

        return null;
    }
    const selectProf = (id) => {
        setSelectedProfessions([...id]);
    }

    const getDefaultProfessions = () => {
        if (selectedProfession && professions && professions.length) {
            let prf = professions.filter(x => selectedProfession.includes(x.professionId));
            if (prf)
                return prf.map((comp) => {
                    return {label: comp.name, value: comp.professionId}
                })
        }

        return null;
    }
    return (
        <div className="profile_editor">
            <div className="active student-blks">
                <div className="student-info-block">
                    <div className="add-photo add-photo-org">
                        <span>Выберите файл или перетащите сюда</span>
                        <div className="chosen_avatar">
                            {(user?.avatarId || croppedImage) && <img src={selectedAvatar} alt="avatar"/>}
                        </div>
                        <input
                            name="avatar"
                            accept="image"
                            value={""}
                            onChange={handleAvatarFileChange} type="file" title="Выберите файл или перетащите сюда"/>
                    </div>
                    <div className="student-info-blk">
                        <form ref={formRef} onSubmit={e => e.preventDefault()} className="search-form">

                            {isCompany && (
                                <div className="flex">
                                    <div className="search-blk create-blk">
                                        <label htmlFor="Inn">ИНН</label>
                                        <input disabled className={"disabled"} defaultValue={user?.inn}
                                               data-field={"inn"} type="text"
                                               placeholder="ИНН"
                                               id="Inn"/>
                                    </div>


                                    <div className="search-blk create-blk ">
                                        <label htmlFor="Name">Название организации</label>
                                        <input defaultValue={user?.name} type="text"
                                               name={"name"}
                                               placeholder="Ведите короткое название"
                                               id="Name"/>
                                    </div>
                                    <div className="search-blk create-blk">
                                        <label htmlFor="FullName">Полное название организации</label>
                                        <input name={"longName"} defaultValue={user?.longName}
                                               type="text" placeholder="Введите полное название"
                                               id="FullName"/>
                                    </div>

                                    <div className="search-blk create-blk">
                                        <label htmlFor="Ogrn">ОГРН</label>
                                        <input defaultValue={user?.ogrn} name={"ogrn"} type="text"
                                               placeholder="ОГРН"
                                               id="Ogrn"/>
                                    </div>

                                    <div className="search-blk create-blk">
                                        <label>Область деятельности</label>
                                        <CustomSelect
                                            placeholder={"Область деятельности"}
                                            refValue={activities && activityIds && activities.length ? getDefaultActivity() : null}
                                            onChange={(e) => {
                                                setSelectedActivities([...e])
                                                //let org = {...user};
                                                //org.activityIds = e;
                                                //setOrganization(org);
                                            }}
                                            isMulti={true}
                                            options={activities.map((comp) => {
                                                return {label: comp.name, value: comp.activityId}
                                            })}
                                        />
                                    </div>
                                    <div className="search-blk create-blk ">
                                        <label>Профессии</label>
                                        <CustomSelect
                                            placeholder={"Выберите профессию"}
                                            id={"profsRef"}
                                            refValue={selectedProfession && selectedProfession.length ? getDefaultProfessions() : null}
                                            onChange={(e) => {
                                                setSelectedProfession([...e])
                                            }}
                                            isMulti={true}
                                            options={professions.map((comp) => {
                                                return {label: comp.name, value: comp.id}
                                            })}
                                        />
                                        {/*  <FilterAccordion title={"Список профессий"}>
                                        <Professions professions={professions} selectedProfession={selectedProfession}
                                                     selectProf={selectProf}/>
                                    </FilterAccordion>*/}
                                    </div>

                                    <div className="search-blk create-blk">
                                        <label htmlFor="Description">Описание</label>
                                        <textarea defaultValue={user?.description} name={"description"}
                                                  placeholder="Описание"
                                                  id="Description"></textarea>
                                    </div>
                                    <div className="search-blk create-blk">
                                        <label htmlFor="Requizite">Реквизиты</label>
                                        <textarea defaultValue={user?.requisites} name={"requisites"}
                                                  placeholder="Реквизиты"
                                                  id="Requizite"></textarea>
                                    </div>
                                    <div className="search-blk create-blk ">
                                        <label htmlFor="Website">Сайт организации</label>
                                        <input defaultValue={user?.site} name={"site"} type="text"
                                               placeholder="https://example.com"
                                               id="Website"/>
                                    </div>

                                </div>
                            )}
                            {!isCompany && (
                                <StudentBlock selectedProfession={selectedProfession}
                                              getDefaultProfessions={getDefaultProfessions}
                                              setSelectedProfession={setSelectedProfession} user={user} info={null}/>
                            )}
                            <div className="buttons_block" style={{marginTop: 30}}>
                                {/* <button
                                    className="secondary_button gray_button"
                                    onClick={(event) => {
                                        event.preventDefault();
                                        event.stopPropagation();
                                        onClose?.();
                                    }}
                                >
                                    Отменить
                                </button>*/}
                                <button onClick={() => saveProfileChanges(formRef.current)}
                                        className="secondary_button turquoise_button">
                                    Сохранить
                                </button>
                            </div>
                            {/*  {isCompany &&
                                <div className="choice_profession shadow_block">
                                    <h2>
                                        Выбор области деятельности
                                    </h2>
                                    <div className="bubble_container">
                                        <div className="search-blk create-blk">
                                            <label>Область деятельности</label>
                                            <CustomSelect
                                                name={"activityIds"}
                                                placeholder={"Область деятельности"}
                                                refValue={activities && activityId && activities.length ? getDefaultActivity() : null}
                                                onChange={(e) => {
                                                    let org = {...user};
                                                    org.activityIds = e;
                                                    //setOrganization(org);
                                                }}
                                                isMulti={true}
                                                options={activities.map((comp) => {
                                                    return {label: comp.name, value: comp.activityId}
                                                })}
                                            />
                                        </div>
                                    </div>
                                </div>
                            }*/}

                            {/* <div className="choice_profession shadow_block">
                                <Tooltip id="professions" place="bottom" className="tooltip">
                                    Вы можете выбрать только 3 профессии, помимо тех, которые
                                    предполагает ваша образовательная программа
                                </Tooltip>
                                <h2>
                                    Выбор профессий
                                    <span className="i-hint" data-tooltip-id="professions"></span>
                                </h2>
                                <div className="bubble_container">
                                    {userProfessions ? (
                                        userProfessions.map((profession) => (
                                            <span
                                                key={profession.id}
                                                className={
                                                    profession.userSelected
                                                        ? "bubble"
                                                        : "bubble bubble_unchanged"
                                                }
                                            >
                      {profession.name}
                                                {profession.userSelected && (
                                                    <span
                                                        className="i-close"
                                                        onClick={() => deleteProfession(profession.id)}
                                                    />
                                                )}
                    </span>
                                        ))
                                    ) : (
                                        <div className="empty">Ничего не найдено :(</div>
                                    )}
                                </div>
                                <ProfessionsSelectWrapper>
                                    <ProfessionsSelector
                                        searchRef={searchRef}
                                        maxCount={isCompany ? 10 : 3}
                                        defaultUserProfessions={defaultUserProfessions}
                                        selectedProfessions={selectedProfessions}
                                        onChangeSelectedProfessions={setSelectedProfessions}
                                    />
                                </ProfessionsSelectWrapper>
                            </div>*/}


                        </form>
                    </div>

                </div>

            </div>

            <ModalCustom
                title="Подготовка Аватара"
                active={avatarCropModalActive}
                setActive={setAvatarCropModalActive}
            >
                <CropModalContent
                    image={selectedAvatarImage ? selectedAvatarImage.url : undefined}
                    onClose={(croppedImage) => {
                        setCroppedImage(croppedImage);
                        setAvatarCropModalActive(false);
                    }}
                />
            </ModalCustom>
        </div>
    );
};

export default ProfileEditor;

const ProfessionsSelectWrapper = styled.div`
  margin-top: 20px;
  overflow: visible;

  height: 330px;
`;
