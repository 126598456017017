import React from'react';
import {useVariables} from "@hooks/useVariables";
import {SHOP, UPDATE_AVATAR_PRODUCT, UPDATE_AVTAR_V2} from "@api/endpoints";
import {useSelector} from "react-redux";


const ShopActions = () =>{
    const user = useSelector((state) => state.user.user);
    const requestGet = useVariables().requestOptionsGET;
    const requestDelete = useVariables().requestOptionsDELETE;
    const requestConstPost = useVariables().constRequestOptionsPOST;
    const constRequestOptionsFormDataPOST = useVariables().constRequestOptionsFormDataPOST;

    const createProduct = (product) =>{
        return fetch(SHOP.CREATE_PRODUCT, requestConstPost(product));
    }
    const removeProduct = (productId) =>{
        return fetch(SHOP.REMOVE_PRODUCT(productId),requestDelete);
    }
    const uploadAvatar = async (file,productId) => {
        let getFile = await fetch(file);
        let blob = await getFile.blob();

        const formData = new FormData();
        formData.append("file", blob);

        const request = constRequestOptionsFormDataPOST(formData);
        let uploadAvatar = await fetch(UPDATE_AVATAR_PRODUCT(productId), request)
    }

    return {
        createProduct,
        uploadAvatar,
        removeProduct,
    }
}

export default ShopActions;